import { make } from "vuex-pathify";

const state = {
  totalNotificacoesPadrao: 0,
  usuarios: localStorage.getItem("usuarios"),
  notificacoes: [],
  summary: [],
  opcaoSelecionada: "Não Lidos",
  mensagemDeErro: "",
  mostrarDialogModal: false,
  carregandoNotificacaoes: true,
  carregandoTabelaNotificacaoes: true,
  adicionarResultadosATabela: false,
  lastTimestamp: 0,
  carregandoSummary: true,
  donoUid: localStorage.getItem("donoUid"),
  clientUids: localStorage.getItem("clientUids"),
  periodoEscolhido: localStorage.getItem("periodoEscolhido"),
  aoVivo: true,
  numeroNotificacoes: 0,
  searchTabelaNotificacoes: "",
  tiposDescricoes: {},
  filtrarPorTipo: "",
  numeroTotalNotificacoes: 0,
  numeroNotificacoesBaixadas: 0,
  notificacoesParaImpressao: [],
};
const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),
};

const getters = {
  ...make.getters(state),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
