<template>
  <v-dialog v-model="estaAberto" width="400">
    <v-card width="400" class="pa-4">
      <div
        class="d-flex align-center flex-column"
        v-if="porcentagemNotificacoes !== 100"
      >
        <p>Baixando notificações {{ porcentagemNotificacoes }}%</p>
        <v-progress-linear
          color="blue"
          :value="porcentagemNotificacoes"
        ></v-progress-linear>
      </div>

      <div class="d-flex align-center flex-column" v-else>
        <p>Montando a tabela</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store/index.js";

export default {
  name: "CarregandoNotificacoesModal",
  computed: {
    estaAberto() {
      return store.get("app/carregando");
    },
    porcentagemNotificacoes() {
      const numeroNotificacoesBaixadas = store.get(
        "home/numeroNotificacoesBaixadas"
      );
      const numeroTotalNotificacoes = store.get("home/numeroTotalNotificacoes");
      return parseInt(
        (numeroNotificacoesBaixadas * 100) / numeroTotalNotificacoes
      );
    },
  },
};
</script>
