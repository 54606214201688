<template>
    <v-dialog v-model="abrirDialog" :width="width" @click:outside="closeModal">
      <v-alert class="mb-0 white--text text-center" border="left" :color="color">
        {{ mensagem }}
      </v-alert>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogModal',
    props: {
        width: {
            type: String,
            required: true,
            default: '400'
        },
        color: {
            type: String,
            required: true,
            default: 'blue'
        },
        abrir: {
            type: Boolean,
            required: true,
        },
        mensagem: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            abrirDialog: false,
        }
    },
    watch: {
      abrir: function(val) {
        this.abrirDialog = val
      }
    },
    methods: {
        closeModal() {
            this.$emit('close-dialog')
        }
    }
}
</script>