import Vue from 'vue'
import VueRouter from 'vue-router'
import { trailingSlash } from "@/util/helpers";
import store from '@/store/index'
import { layout, route } from "@/util/routes";

Vue.use(VueRouter)

const routes = [
  layout("Default", [
    route("HomeView", null, "inicio", "views"),
  ]),
  layout("Login", [
    route("LoginView", null, "login", "views"),
  ])
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const login = store.get('login/login')

  if(to.path == '/' || to.path == '') {
    next('/inicio')
  } else if(to.path == '/login' || login) {
    next()
  }else if(!login) {
    next("/login")
  } else {
    next(trailingSlash(to.path))
  }
});

export default router
