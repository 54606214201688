<template>
  <v-card :width="width" :height="height">
    <v-card-text class="pt-0">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        background-color="white"
        dense
        hide-details>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: 'SearchInfo',
    props: {
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: false
      }
    },
    data() {
        return {
            search: ''
        }
    },
    watch: {
      search: function (val) {
        this.$emit('search', val)
      }
    }
}
</script>

<style>

</style>