<template>
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
</template>

<script>

export default {
  name: "App",
  metaInfo: {
    title: "LBC-Notificações",
    titleTemplate: "%s | Aplicativo de Gestão",
    htmlAttrs: { lang: "br" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>

<style>
html {
  overflow-y: hidden !important;
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>

